<template>
  <section class="text-center">
    <h2 class="heading-2 mb-16">
      {{ i18n(translations.reassuranceTitle) }}
    </h2>
    <p class="body-1">
      {{ i18n(translations.reassuranceSubtitle) }}
    </p>
    <ul class="grid-standard list-none">
      <li
        v-for="{ id, icon, title, description } in REASSURANCE_BLOCKS"
        :key="id"
        class="mt-32 md:col-span-2 lg:col-span-3"
      >
        <component :is="icon" class="m-auto" size="large" />
        <p class="body-2-bold mt-16">
          {{ title }}
        </p>
        <p class="body-2">
          {{ description }}
        </p>
      </li>
    </ul>
  </section>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconReassuranceArrowFilled } from '@ds/icons/IconReassuranceArrowFilled'
import { IconReassuranceGlobeFilled } from '@ds/icons/IconReassuranceGlobeFilled'
import { IconReassuranceLockFilled } from '@ds/icons/IconReassuranceLockFilled'
import { IconReassuranceSmileyFilled } from '@ds/icons/IconReassuranceSmileyFilled'

import translations from './Reassurance.translations'

const i18n = useI18n()

const REASSURANCE_BLOCKS = [
  {
    id: 'reassurance-best-offer',
    icon: IconReassuranceSmileyFilled,
    title: i18n(translations.reassuranceBestOfferTitle),
    description: i18n(translations.reassuranceBestOfferDescription),
  },
  {
    id: 'reassurance-impact',
    icon: IconReassuranceGlobeFilled,
    title: i18n(translations.reassuranceImpactTitle),
    description: i18n(translations.reassuranceImpactDescription),
  },
  {
    id: 'reassurance-quality',
    icon: IconReassuranceArrowFilled,
    title: i18n(translations.reassuranceQualityTitle),
    description: i18n(translations.reassuranceQualityDescription),
  },
  {
    id: 'reassurance-service',
    icon: IconReassuranceLockFilled,
    title: i18n(translations.reassuranceServiceTitle),
    description: i18n(translations.reassuranceServiceDescription),
  },
]
</script>
