<template>
  <div>
    <h2 class="heading-2 mb-16">
      {{ i18n(translations.title) }}
    </h2>
    <RevCardCarousel
      :next-alternative-text="i18n(translations.cardNext)"
      :prev-alternative-text="i18n(translations.cardPrev)"
    >
      <RevEditorialCard
        v-for="comparison in suggestedComparisons"
        :key="comparison.customContent.title"
        class="mb-4"
        :content="comparison.customContent.cardDescription"
        :cta="i18n(translations.cardCta)"
        :data-selector="SEO_DATA_SELECTOR_COMPARISON_CARD"
        :image="comparison.customContent.image || PLACEHOLDER_IMAGE"
        :title="comparison.customContent.title"
        :title-kicker="i18n(translations.cardKicker)"
        title-tag="h3"
        :to="comparison.link"
        @click="trackComparisonCardClick(comparison)"
      />
    </RevCardCarousel>
  </div>
</template>

<script setup lang="ts">
import { type SuggestedComparison } from '@backmarket/http-api/src/api-specs-model-comparison/models/category-content'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCardCarousel } from '@ds/components/CardCarousel'
import { RevEditorialCard } from '@ds/components/EditorialCard'

import translations from './SuggestedComparisons.translations'

defineProps<{
  suggestedComparisons: SuggestedComparison[]
}>()

const SEO_DATA_SELECTOR_COMPARISON_CARD = 'compare-card'
const PLACEHOLDER_IMAGE =
  'https://d37cfnv61yd15h.cloudfront.net/model_comparison/images/comparison-image-1.jpg'

const i18n = useI18n()

const { trackClick } = useTracking()

function trackComparisonCardClick(comparison: SuggestedComparison) {
  trackClick({
    name: comparison.customContent.title,
  })
}
</script>
