import { getCategoryContent } from '@backmarket/http-api/src/api-specs-model-comparison/endpoints'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

import { useCurrentCategory } from './useCurrentCategory'

export function useFetchCategory() {
  return useHttpFetch(getCategoryContent, {
    pathParams: {
      category: useCurrentCategory(),
    },
  })
}
