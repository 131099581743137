<template>
  <div>
    <h2 class="heading-2 mb-16">
      {{ i18n(translations.featuredArticlesTitle) }}
    </h2>

    <ArticleCardsCarousel :articles="featuredArticles" />
  </div>
</template>

<script lang="ts" setup>
import { useRequestURL, useRouter } from '#imports'
import { computed } from 'vue'

import { type ModelComparisonCategoryContent } from '@backmarket/http-api/src/api-specs-model-comparison/models/category-content'
import ArticleCardsCarousel from '@backmarket/nuxt-layer-cms/ArticleCard/ArticleCardsCarousel.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import { buildGetArticleHrefFn } from '~/scopes/article/helpers/buildGetArticleHrefFn'

import translations from '../AdditionalContent/AdditionalContent.translations'

const props = defineProps<{
  articles: ModelComparisonCategoryContent['content']['featuredArticles']
}>()
const i18n = useI18n()
const router = useRouter()
const currentUrl = useRequestURL()

const getLink = buildGetArticleHrefFn({ router, currentUrl })

const featuredArticles = computed(() => {
  return props.articles.map((art) => {
    const { text, title, translatedCategory, banner, bannerAlt, link, pk } = art

    const article = {
      id: `${pk}`,
      category: translatedCategory,
      imageAlt: bannerAlt,
      imageSrc: banner,
      link: getLink(link),
      text,
      title,
    }

    return article
  })
})
</script>
