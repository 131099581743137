<template>
  <div v-if="data" class="text-static-default-hi">
    <Breadcrumb :category="data.category" />

    <RevContainer class="bg-surface-default-low pb-56 md:pb-72">
      <h1 class="heading-1 mt-32 text-center">
        {{ data.category.pageTitle }}
      </h1>
      <div class="mb-56">
        <ComparisonForm :autocomplete-config="data.algoliaConfig.searchBox" />
      </div>

      <RevDivider class="mb-56" />

      <SuggestedCategories />
    </RevContainer>

    <AdditionalContent :content="data.content" :custom-content-first="false" />
  </div>
</template>

<script lang="ts" setup>
import { RevContainer } from '@ds/components/Container'
import { RevDivider } from '@ds/components/Divider'

import AdditionalContent from '../components/AdditionalContent/AdditionalContent.vue'
import Breadcrumb from '../components/Breadcrumb/Breadcrumb.vue'
import ComparisonForm from '../components/ComparisonForm/ComparisonForm.vue'
import SuggestedCategories from '../components/SuggestedCategories/SuggestedCategories.vue'
import { useFetchCategory } from '../composables/useFetchCategory'
import { useModelsComparisonHead } from '../composables/useModelsComparisonHead'

const { data } = await useFetchCategory()

useModelsComparisonHead(() => data.value?.content)
</script>
