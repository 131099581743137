import { type MaybeRefOrGetter, computed, ref, toValue } from 'vue'

import pDebounce from 'p-debounce'

import type { Model } from '../models/model'

type InputOption = { key?: string; label: string }

export function useModelInput(
  searchModels: (text: string) => Promise<Model[]>,
  {
    selectedModels = [],
    debounceTimeMs = 0,
    initialValue = '',
  }: {
    selectedModels?: MaybeRefOrGetter<Model[]>
    debounceTimeMs?: number
    initialValue?: string
  } = {},
) {
  const options = ref<InputOption[]>([])

  const selectedModelName = ref(initialValue)

  const selectedModelsNames = computed(() =>
    toValue(selectedModels).map((m) => m.name),
  )

  function isNotSelected(model: Model) {
    return !selectedModelsNames.value.includes(model.name)
  }

  function select(modelOption: InputOption) {
    if (!modelOption) return
    selectedModelName.value = modelOption.label
  }

  const handleInput = pDebounce(async (text: string) => {
    if (!text || text.length < 2) return

    const models = await searchModels(text)

    const MAX_MODEL_OPTIONS_SHOWN_IN_DROPDOWN = 5

    options.value = models
      .filter((m) => isNotSelected(m))
      .map((m) => ({ key: m.id, label: m.name }))
      .slice(0, MAX_MODEL_OPTIONS_SHOWN_IN_DROPDOWN)
  }, debounceTimeMs)

  return { options, handleInput, selectedModelName, select }
}
