export default {
  title: {
    id: 'product_comparison_cross_links_title',
    defaultMessage: 'Our recent comparisons',
  },
  cardNext: {
    id: 'buying_guide_slider_next_slide',
    defaultMessage: 'Display next slide',
  },
  cardPrev: {
    id: 'buying_guide_slider_prev_slide',
    defaultMessage: 'Display previous slide',
  },
  cardKicker: {
    id: 'product_comparison_cross_links_card_kicker',
    defaultMessage: 'Comparison',
  },
  cardCta: {
    id: 'product_comparison_cross_links_card_cta',
    defaultMessage: 'See more',
  },
} as const
