<template>
  <div class="w-full md:max-w-[35.2rem]">
    <!-- TODO: create (or reuse) translation for 'Clear' -->
    <RevInputAutocomplete
      :id
      v-model="selectedModelName"
      class="mx-16 my-8"
      clear-button-aria-label="Clear"
      :label="i18n(translations.formSelectLabel)"
      :options
      type="text"
      @input="onClick"
      @select-item="onItemSelected"
    />
  </div>
</template>

<script lang="ts" setup>
import type { MultipleQueriesQuery } from '@algolia/client-search'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevInputAutocomplete } from '@ds/components/InputAutocomplete'

import { useModelInput } from '../../composables/useModelInput'
import { useSearchModelsToCompare } from '../../composables/useSearchModelsToCompare'
import type { SelectedModels } from '../../models/selected-models'

import translations from './InputsModelAutocomplete.translations'

const i18n = useI18n()

interface Option {
  key?: string
  label: string
}

const props = defineProps<{
  autocompleteConfig: { apiKey: string; queries: MultipleQueriesQuery[] }
  id: string
  selectedModels: SelectedModels
  initialModelName?: string
}>()

const emits = defineEmits<{
  (e: 'model-selected', modelName: string): void
  (e: 'model-unselected'): void
}>()

const { searchModels } = useSearchModelsToCompare({
  autocompleteConfig: props.autocompleteConfig,
  selectedModelsNames: () => props.selectedModels,
})

const { handleInput, options, selectedModelName, select } = useModelInput(
  searchModels,
  {
    debounceTimeMs: 100,
    initialValue: props.initialModelName,
  },
)

function onItemSelected(option: Option) {
  select(option)
  emits('model-selected', option.label)
}

function onClick(e: InputEvent) {
  const inputText = (e.target as HTMLInputElement).value
  if (inputText === '') emits('model-unselected')
  handleInput(inputText)
}
</script>
