export default {
  reassuranceTitle: {
    id: 'product_comparison_reassurance_title',
    defaultMessage: 'From phones to fridges, you can’t choose wrong.',
  },
  reassuranceSubtitle: {
    id: 'product_comparison_reassurance_subtitle',
    defaultMessage:
      'Back Market only sells the highest quality renewed items, at the best prices, risk-free.',
  },
  reassuranceBestOfferTitle: {
    id: 'product_comparison_reassurance_bestoffer_title',
    defaultMessage: 'Best offer available',
  },
  reassuranceBestOfferDescription: {
    id: 'product_comparison_reassurance_bestoffer_description',
    defaultMessage: 'Selected by our price-quality algorithm',
  },
  reassuranceImpactTitle: {
    id: 'product_comparison_reassurance_impact_title',
    defaultMessage: 'Positive impact',
  },
  reassuranceImpactDescription: {
    id: 'product_comparison_reassurance_impact_description',
    defaultMessage: 'An alternative to new, joyful and elegant.',
  },
  reassuranceQualityTitle: {
    id: 'product_comparison_reassurance_quality_title',
    defaultMessage: 'Guaranteed quality',
  },
  reassuranceQualityDescription: {
    id: 'product_comparison_reassurance_quality_description',
    defaultMessage: 'Our partner merchants are continuously monitored',
  },
  reassuranceServiceTitle: {
    id: 'product_comparison_reassurance_service_title',
    defaultMessage: 'Amazing service',
  },
  reassuranceServiceDescription: {
    id: 'product_comparison_reassurance_service_description',
    defaultMessage: 'Secure shopping experience',
  },
} as const
