<template>
  <section v-if="shouldDisplayCustomContent" class="grid-standard">
    <h2 class="heading-1 mb-24 md:col-span-4 lg:col-span-8 lg:col-start-3">
      {{ title }}
    </h2>
    <!-- eslint-disable vue/no-v-html -->
    <!-- nosemgrep: javascript.vue.security.audit.xss.templates.avoid-v-html.avoid-v-html -->
    <div
      v-if="body"
      class="text-static-default-mid body-1 md:col-span-4 lg:col-span-8 lg:col-start-3"
      :class="style.textBody"
      data-selector="compare-text"
      v-html="body"
    />
  </section>
</template>

<script setup lang="ts">
import { computed, useCssModule } from 'vue'

import { type ModelComparisonCategoryContent } from '@backmarket/http-api/src/api-specs-model-comparison/models/category-content'

const props =
  defineProps<ModelComparisonCategoryContent['content']['customContent']>()

const style = useCssModule()

const shouldDisplayCustomContent = computed(() => !!props.title && !!props.body)
</script>

<style lang="css" module>
.textBody h3 {
  @apply heading-3 mb-12;
}

.textBody p {
  @apply mb-12;
}

.textBody a {
  @apply body-1-link;
}
</style>
