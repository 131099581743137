import { useRouter } from '#imports'
import { ref, watch } from 'vue'

import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { spacesToHyphens } from '@backmarket/utils/string/spacesToHyphens'

import {
  NO_MODEL,
  NO_MODELS_SELECTED,
  type SelectedModels,
} from '../models/selected-models'
import { MODELS_COMPARISON } from '../routes-names'

const modelToSlug = (model: string) => spacesToHyphens(model).toLowerCase()

export function useSelectModels({
  initialModels = NO_MODELS_SELECTED,
}: { initialModels?: SelectedModels } = {}) {
  const selectedModelsNames = ref<SelectedModels>(initialModels)

  const router = useRouter()

  function select({
    modelName,
    position,
  }: {
    modelName: string
    position: 0 | 1
  }) {
    if (position === 0)
      selectedModelsNames.value = [modelName, selectedModelsNames.value[1]]
    else selectedModelsNames.value = [selectedModelsNames.value[0], modelName]
  }

  function unselect({ position }: { position: number }) {
    if (position === 0)
      selectedModelsNames.value = [NO_MODEL, selectedModelsNames.value[1]]
    else selectedModelsNames.value = [selectedModelsNames.value[0], NO_MODEL]
  }

  watch(selectedModelsNames, (selectedModels) => {
    const isNotEmpty = (model: string) => !isEmpty(model)

    const [modelA, modelB] = selectedModels

    if (isNotEmpty(modelA) && isNotEmpty(modelB)) {
      void router.push({
        name: MODELS_COMPARISON.COMPARISON_RESULTS,
        params: {
          productA: modelToSlug(modelA),
          productB: modelToSlug(modelB),
        },
        query: {},
      })
    }
  })

  return { selectedModelsNames, select, unselect }
}
